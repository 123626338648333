<template>
    <v-data-table
        :headers="headers"
        :items="loading ? [] : items"
        :items-per-page="itemsPerPage"
        :hide-default-footer="!hideDefaultFooter"
        :sort-by="sortByHeaders"
        :sort-desc="sortByDesc"
        class="elevation-0 reports__table"
        :loading="loading"
        height="100%"
    >
        <template v-slot:item.name="{ item }">
            <span class="text-uppercase"> {{ item.firstname || item.name }} {{ item.lastname }} </span>
        </template>

        <template v-slot:item.progress_state="{ item }">
            <v-chip
                v-if="item.progress_state === 'COMPLETED'"
                v-bind="attrs"
                v-on="on"
                pill
                color="green darken-1"
            >
                <v-icon color="white" small> mdi-clipboard-check </v-icon>
                <span class="white--text body-2 mx-1 font-weight-medium"> 
                    {{ $t('evaluate.evaluations.states.completed') }}
                </span>
            </v-chip>

            <v-chip
                v-else-if="item.progress_state === 'IN_PROGRESS'"
                v-bind="attrs"
                v-on="on"
                pill
                color="amber lighten-3"
            >
                <v-icon color="grey darken-2" small> mdi-clipboard-minus </v-icon>
                <span class="grey--text darken-2 body-2 mx-1 font-weight-medium">
                    {{ $t('evaluate.evaluations.states.inProgress') }}
                </span>
            </v-chip>

            <v-chip v-else v-bind="attrs" v-on="on" pill color="grey lighten-3">
                <v-icon color="grey darken-2" small> mdi-clipboard-minus </v-icon>
                <span class="grey--text darken-2 body-2 mx-1 font-weight-medium">
                    {{ $t('evaluate.evaluations.states.notInitiated') }}
                </span>
            </v-chip>
        </template>

        <template v-slot:item.progress_state="{ item }">
            <v-chip
                v-if="item.progress_state === 'COMPLETED'"
                v-bind="attrs"
                v-on="on"
                pill
                color="green darken-1"
            >
                <v-icon color="white" small> mdi-clipboard-check </v-icon>
                <span class="white--text body-2 mx-1 font-weight-medium"> 
                    {{ $t('evaluate.evaluations.states.completed') }}
                </span>
            </v-chip>

            <v-chip
                v-else-if="item.progress_state === 'IN_PROGRESS'"
                v-bind="attrs"
                v-on="on"
                pill
                color="amber lighten-5"
            >
                <v-icon color="amber darken-3" small> mdi-clipboard-minus </v-icon>
                <span class="amber--text darken-3 body-2 mx-1 font-weight-medium">
                    {{ $t('evaluate.evaluations.states.inProgress') }}
                </span>
            </v-chip>

            <v-chip v-else v-bind="attrs" v-on="on" pill color="grey lighten-3">
                <v-icon color="grey darken-2" small> mdi-clipboard-minus </v-icon>
                <span class="grey--text darken-2 body-2 mx-1 font-weight-medium">
                    {{ $t('evaluate.evaluations.states.notInitiated') }}
                </span>
            </v-chip>
        </template>

        <!-- Actions -->
        <template v-slot:item.id="{ item }">
            <v-row class="flex-nowrap" align-content="center" justify="center">
                <v-row v-if="item.progress_state === 'IN_PROGRESS' || item.progress_state === 'COMPLETED'" align-content="center" justify="center">
                    <v-btn
                        icon
                        elevation="0"
                        class="primary ma-1 pa-3"
                        small
                        @click="goToStats(item)"
                    >
                        <v-icon small class="pa-3" color="white">mdi-poll</v-icon>
                    </v-btn>

                    <!-- Delete -->
                    <v-btn
                        icon
                        elevation="0"
                        class="red lighten-5 ma-1 pa-3"
                        small
                        @click="deleteBookAssigment(item)"
                    >
                        <v-icon small class="pa-3" color="red">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </v-row>
                <SimpleButton 
                    v-else
                    icon="mdi-file-check-outline"
                    :text="$t('evaluate.actions.evaluate')"
                    @click="handleEvaluation(item.id)"
                    rounded
                />
            </v-row>
        </template>

        <template v-slot:item.correct_words_per_minute="{ item }">
            <v-row class="flex-nowrap" align-content="center" justify="center">
                <span v-if="item.correct_words_per_minute" class="text-no-wrap">{{
                    item.correct_words_per_minute
                }}</span>
                <span v-else class="text-no-wrap">-</span>
            </v-row>
        </template>
        <template v-slot:item.fluency_level="{ item }">
            <v-row class="flex-nowrap" align-content="center" justify="center">
                <span v-if="item.fluency_level" class="text-no-wrap">{{
                    $t(`evaluate.evaluations.readingSpeed.${item.fluency_level}`)
                }}</span>
                <span v-else class="text-no-wrap">-</span>
            </v-row>
        </template>
        <template v-slot:item.reading_category="{ item }">
            <v-row class="flex-nowrap" align-content="center" justify="center">
                <span v-if="item.reading_category" class="text-no-wrap">{{
                    $t(`evaluate.evaluations.readingQualityValues.${item.reading_category}`)
                }}</span>
                <span v-else class="text-no-wrap">-</span>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import SimpleButton from '@/components/core/buttons/simpleButton'

export default {
    name: 'StudentsEvaluationResutlsStudentListTable',
    components: {
        SimpleButton,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        items: {
            type: Array,
            default: () => [],
            required: false,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
            required: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: true,
            required: false,
        },
        sortByHeaders: {
            type: Array,
            default: () => [],
            required: false,
        },
        sortByDesc: {
            type: Boolean,
            default: false,
            required: false,
        },
        headers: {
            type: Array,
            default: () => [],
            required: false,
        },
        showDetail: {
            type: Boolean,
            default: false,
            required: false,
        },
        showEvaluate: {
            type: Boolean,
            default: false,
            required: false,
        },
        showActions: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    methods: {
        handleEvaluation(studentId) {
            this.$emit('on-evaluate', studentId)
        },
        goToStats(evaluation) {
            this.$emit('on-stats', evaluation)
        },
        deleteBookAssigment(evaluation) {
            this.$modal.confirm(
                this.$t('library.confirm_delete'),
                this.$t('evaluate.actions.confirmation.delete'),
                async () => {
                    await this.$emit('on-delete', evaluation.diagnosis)
                }
            )
        },
    },
}
</script>

<style scope lang="scss">
@import '../../../../assets/style/colors.scss';

.headers-table {
    background: $purple-primary !important;
}

.reports__table .v-data-table-header {
    background-color: $purple-primary;
}
.reports__table .v-data-table-header .sortable.active {
    background-color: #d7d2ef;
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: none !important;
}
.evaluation-option {
    cursor: pointer;
}

.text-no-wrap {
    word-break: keep-all;
}
</style>
